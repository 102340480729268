import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import img from "~assets/image/customer-stories/feature-story-2.png";
import { SuperTag, Link } from '~components';

export default function BurnsCapitalWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="How Burns Capital Boosted Its Connection Rate to 38% With We-Connect"/> </Widget.Heading>
          
        </Widget.Box>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
    <Widget.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Widget.Image>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Burns Capital</Widget.Company>
        <Widget.Text>Burns Capital Partners is a New York-based private equity firm teaching tech professionals how to expand and diversify their income through real estate investments.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Real Estate</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text>2-10 employees</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences, Analytics & Reporting</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>Get Your Message Across With Automation</b></Widget.Title>
        <Widget.SubPara>“A large part of [my business] starts with getting my message out and finding those people in tech that are interested in this type of investment. And that’s where We-Connect comes in.”</Widget.SubPara>
        <Widget.Text>For a long time, TJ Burns, Founder of Burns Capital Partners, tried to break into the business of real estate investing. But,  that came with a lot of challenges. He lived in an expensive market with high competition for real estate investments, he didn't have much time – as he worked a demanding tech job, and he didn’t know anyone in the industry – meaning he didn’t have access to great deals.
        </Widget.Text>
        <Widget.Text>
        Eventually, TJ started gaining some traction. Leveraging the money he was making in tech, he began making passive investments alongside other, more experienced professionals who had great deals and were just looking for capital.
        </Widget.Text>
        <Widget.Text>
        From this experience, TJ discovered that there was a need for someone to help other future investors like himself. He realized he could be the conduit between people like him – tech professionals interested in making investments – and people in investment real estate looking to raise money from retail investors.
        </Widget.Text>


        <Widget.Title as="h2"><b>Finding an Automation Tool That’s 100% Secure</b></Widget.Title>
        <Widget.SubPara>“Before this business, I wasn’t really taking LinkedIn super seriously. But, with this business, I needed a way to consistently find this ideal customer and connect with them.”</Widget.SubPara>
        <Widget.Text>Prior to using We-Connect, Burns Capital didn’t have a specific strategy for finding leads. TJ says this was, “Partially because my business was in its infancy and partially because I wasn’t using LinkedIn to my advantage.”</Widget.Text>
        <Widget.SubPara>“Without We-Connect, I would have to do [lead-generation] myself, which wasn’t a timely option, or I would have to hire somebody to do it.”</Widget.SubPara>
        <Widget.Text>So, Burns Captial decided to invest in a LinkedIn automation tool. However, the first tool they used didn’t meet LinkedIn’s security standards. “I kept getting thrown into LinkedIn jail over and over. At first, it was a 3-hour ban, then a day, and then two days.”</Widget.Text>
        <Widget.SubPara>
        “I needed to find a platform that was safer – and We-Connect was that platform.”
        </Widget.SubPara>

        <Widget.Title as="h2"><b>Implementation and Running the First Campaign</b></Widget.Title>
        <Widget.Text>In half a day, TJ imported his first set of lists pulled from LinkedIn’s Sales Navigator, built out a campaign, personalized messages, and started seeing results. “It doesn’t really require a lot of maintenance,” said TJ. “I might run a campaign of 1,000 prospects, and I can just leave it. I know it’s going to continue to run in the background, and I’ll see those leads come through.”</Widget.Text>
        <Widget.Text>Burns Capital runs multiple lead-generation and lead-nurturing campaigns as part of its overall strategy. One campaign specifically targeted Amazon employees, a part of a real estate investment Slack channel. He brought this list into We-Connect and ran a campaign allowing him to connect with these users. From this campaign, TJ saw a 38% connection rate with a 17% reply rate.</Widget.Text>
        <Widget.SubPara>“So far, We-Connect is one of the most consistent things about my business. I know that every single day, I have 20 to 30 connections going out with around a 30% acceptance rate.” </Widget.SubPara>

        <Widget.Title as="h2"><b>We-Connect as a Lean Solution</b></Widget.Title>
        <Widget.Text>Burns Capital is a lean business. TJ explains, “If something is not paying for itself in multiples, I’m probably not going to stay as a customer.” For his company, We-Connect makes sense. “I would have to replace it with something else that was similar, at a lower price point, wouldn’t get me in trouble, and easy to use. We-Connect already checks all those boxes.”</Widget.Text>
        <Widget.SubPara>“I wish I had a system like [We-Connect] for every part of my business.” </Widget.SubPara>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}